/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';

import {
  Divider,
  Toolbar,
  AppBar,
  Typography,
  Button,
  Box,
  Grid,
  Avatar,
  Link as MuiLink,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigateBefore } from '@material-ui/icons';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Image } from '@/src/components/atoms';
import { Section } from '@/src/components/organisms';
import { HideOnScroll } from '@/src/components/widgets';
import { UserProvider } from '@/src/containers/commons';
import { KoinProvider } from '@/src/containers/commons/KoinProvider';
import StoreThemeProvider from '@/src/containers/commons/StoreThemeProvider';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  section: {
    maxWidth: theme.layout.contentWidth,
    height: '100%',
    width: '100%',
    margin: '0 auto',
    padding: 0,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  main: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.Sidebar ? '75%' : '100%'),
    },
  },
  checklist: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
  },
  stepper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  mobileVoucher: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sidebar: {
    position: 'sticky',
    top: 0,
    [theme.breakpoints.up('md')]: {
      width: '25%',
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  links: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  copyright: {
    color: 'rgba(0,0,0,.5)',
  },
  poweredByLogo: {
    maxWidth: 200,
  },
  footer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bottom: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(9),
  },
}));

const CheckoutLayout = ({
  children,
  className,
  Checklist,
  Messages,
  Stepper,
  StepperProps,
  Sidebar,
  SidebarProps,
  MobileVoucher,
  store,
  themeMode,
  UserProviderProps = { withGuard: true },
}) => {
  const classes = useStyles({ Sidebar });
  const router = useRouter();

  return (
    <StoreThemeProvider store={store} themeMode={themeMode}>
      <UserProvider {...UserProviderProps}>
        <Script
          id="pagseguro-sdk"
          src="https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js"
        />
        <Script id="iugu-sdk" src="https://js.iugu.com/v2" />
        <KoinProvider>
          <div className={clsx(classes.root, className)}>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <HideOnScroll>
                <AppBar elevation={0} color="inherit">
                  <Toolbar className={classes.toolbar}>
                    {store?.theme?.logoHorizontal ? (
                      <div className={classes.logo}>
                        <a href="/families">
                          <img
                            src={store?.theme?.logoHorizontal}
                            alt={store?.displayName}
                            height="100%"
                          />
                        </a>
                      </div>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={store?.logo || '/logo/symbol-svg.svg'} />
                        <Typography variant="h5" className={classes.title}>
                          {store?.displayName || 'Eskolare'}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Button color="inherit" component="a" href="/families">
                      Voltar à loja
                    </Button>
                  </Toolbar>
                  <Divider />
                </AppBar>
              </HideOnScroll>
              <Toolbar />
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <HideOnScroll>
                <AppBar elevation={0} color="inherit">
                  <Toolbar className={classes.toolbar} disableGutters>
                    <IconButton onClick={() => router.back()}>
                      <NavigateBefore />
                    </IconButton>
                    {store?.theme?.logoHorizontal ? (
                      <div className={classes.logo}>
                        <a href="/families">
                          <img
                            src={store?.theme?.logoHorizontal}
                            alt={store?.displayName}
                            height="100%"
                          />
                        </a>
                      </div>
                    ) : (
                      <Avatar src={store?.logo || '/logo/symbol-svg.svg'} />
                    )}
                    <Box sx={{ width: 48 }} />
                  </Toolbar>
                  <Divider />
                </AppBar>
              </HideOnScroll>
              <Toolbar />
            </Box>
            <Section className={classes.section}>
              <div className={classes.wrapper}>
                <div className={classes.main}>
                  {Stepper && (
                    <div className={classes.stepper}>
                      <Stepper {...StepperProps} />
                    </div>
                  )}
                  {Checklist && (
                    <div className={classes.checklist}>
                      <Checklist />
                    </div>
                  )}
                  {Messages && (
                    <div className={classes.checklist}>
                      <Messages />
                    </div>
                  )}
                  {MobileVoucher && (
                    <div className={classes.mobileVoucher}>
                      <MobileVoucher />
                    </div>
                  )}
                  {children}
                </div>
                {Sidebar && (
                  <div className={classes.sidebar}>
                    <Sidebar store={store} {...SidebarProps} />
                  </div>
                )}
              </div>
            </Section>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Divider />
              <Toolbar className={clsx(classes.toolbar, classes.bottom)}>
                <Grid container spacing={2} className={classes.links}>
                  <Grid item>
                    <MuiLink variant="body1" href="/" target="_blank">
                      Ajuda
                    </MuiLink>
                  </Grid>
                  <Grid item>
                    <MuiLink variant="body1" href="/privacy" target="_blank">
                      Política de Privacidade
                    </MuiLink>
                  </Grid>
                  <Grid item>
                    <MuiLink variant="body1" href="/terms" target="_blank">
                      Termos de Uso
                    </MuiLink>
                  </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1 }}></Box>
                {(store?.displayPoweredBy || store === undefined) && (
                  <Box>
                    <Typography variant="body2" component="div" align="right" color="textSecondary">
                      Powered by
                    </Typography>
                    <Link href="/">
                      <a title="Eskolare">
                        <Image
                          className={classes.poweredByLogo}
                          src="/logo/horizontal-color.svg"
                          alt="Eskolare"
                          lazy={false}
                        />
                      </a>
                    </Link>
                  </Box>
                )}
              </Toolbar>
            </Box>
          </div>
        </KoinProvider>
      </UserProvider>
    </StoreThemeProvider>
  );
};

CheckoutLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  Checklist: PropTypes.any,
  Messages: PropTypes.any,
  Sidebar: PropTypes.any,
  MobileVoucher: PropTypes.any,
  SidebarProps: PropTypes.object,
  Stepper: PropTypes.any,
  StepperProps: PropTypes.object,
  store: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  UserProviderProps: PropTypes.object,
};

export default CheckoutLayout;
